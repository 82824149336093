/*
 * @Author: 熊志伟
 * @Date: 2023-12-06 10:28:26
 * @LastEditors: luosi 1297957337@qq.com
 * @LastEditTime: 2025-03-21 15:05:19
 * @FilePath: \dcy-web\packages\utils\index.ts
 * @Description: 通用工具函数
 */

import type { CourseKnowledgePointTreeVO } from '@dcyjs/materials/types/course/index'

export * from './clipboard'
export * from './crypto'
export * from './download'
export * from './formate/formateRelativeTime'
export * from './formate/formateBetweenTime'
export * from './formate/formateIsDay'
export * from './formate/formateIsSameOrAfter'
export * from './formate/formateMinMax'
export * from './formateDate'
export * from './graphics'
export * from './route-listener'
export * from './jsencrypt'
export * from './auth'
export * from './flat'
export * from './constText'
export * from './handleStorage'
export * from './copyImg'
export * from './errorModal'
export * from './is'
export * from './number'

type TargetContext = '_self' | '_parent' | '_blank' | '_top'

export function openWindow(url: string, opts?: { target?: TargetContext, [key: string]: any }) {
  const { target = '_blank', ...others } = opts || {}
  if (import.meta.client) {
    window.open(
      url,
      target,
      Object.entries(others)
        .reduce((preValue: string[], curValue) => {
          const [key, value] = curValue
          return [...preValue, `${key}=${value}`]
        }, [])
        .join(','),
    )
  }
}

// eslint-disable-next-line prefer-regex-literals
export const regexUrl = new RegExp(
  '^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$',
  'i',
)

export function sleep(seconds: number) {
  return new Promise(resolve => setTimeout(resolve, seconds * 1000))
}
// 数字转中文
export function numberToChinese(num: number) {
  const unitChars = ['', '十', '百', '千']
  const digitChars = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']

  const numStr = String(num)
  let result = ''

  for (let i = 0; i < numStr.length; i++) {
    const digit = Number.parseInt(numStr[i])
    const unitIndex = numStr.length - i - 1

    if (digit !== 0) {
      result += digitChars[digit] + unitChars[unitIndex]
    }
    else {
      // 当前数字为零时，判断是否为最后一位或者下一位不为零
      if (i !== numStr.length - 1 && Number.parseInt(numStr[i + 1]) !== 0)
        result += digitChars[digit]
    }
  }

  return result
}

// 阿拉伯数字转中文
export function numberConvertToUppercase(num: number) {
  const upperNumbers = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九', '十', '百', '千', '万', '亿']
  const length = String(num).length
  if (length === 1) {
    return upperNumbers[num]
  }
  else if (length === 2) {
    if (num === 10) {
      return upperNumbers[num]
    }
    else if (num > 10 && num < 20) {
      const index: any = String(num)
      return `十${upperNumbers[index.charAt(1)]}`
    }
    else {
      const index: any = String(num)
      return `${upperNumbers[index.charAt(0)]}十${upperNumbers[index.charAt(1)].replace('零', '')}`
    }
  }
  else {
    // TODO: 超出99暂不考虑
    return ''
  }
}

/**
 * @desorption 设置进度条颜色
 * @param {number} percent 进度条数值
 * @param {number} threshold 阈值，用于区分某一个阈值上下的颜色
 * @param {object} thresholdColor 阈值的颜色
 * @param {object} normalColor 正常的颜色
 * @returns {object} 设置进度条颜色
 */
export function setProgressColor(params: { percent: number, threshold?: number, thresholdColor?: Record<string, string> | string, normalColor?: Record<string, string> | string }) {
  const { percent, threshold = 0.6, thresholdColor = { '0%': '#FF7B50', '100%': '#FFBB56' }, normalColor = { '0%': '#596AFF', '100%': '#77B6FF' } } = params
  if (percent < threshold)
    return thresholdColor
  return normalColor
}

// 判断时间是否是今天
export function isToday(date: any) {
  const today = new Date()
  const specifiedDate = new Date(date)

  // 比较年、月、日
  return today.getFullYear() === specifiedDate.getFullYear()
    && today.getMonth() === specifiedDate.getMonth()
    && today.getDate() === specifiedDate.getDate()
}
export function onEnumToMap(enumObject: Record<string, any>): Map<string, number> {
  const enumMap = new Map<string, number>()
  for (const key in enumObject) {
    if (typeof enumObject[key] === 'number')
      enumMap.set(key, enumObject[key])
  }
  return enumMap
}

// 获取文件名
export function getFileName(name: string) {
  return name.substring(0, name.lastIndexOf('.'))
}
// 获取 .后缀名
export function getExtension(name: string) {
  return name.substring(name.lastIndexOf('.'))
}
// 只获取后缀名
export function getExtensionName(name: string) {
  return name.substring(name.lastIndexOf('.') + 1)
}

/**
 * 获取树节点路径
 * @param {*} curKey 树节点标识的值
 * @param {any} data 树
 * @returns {any} result 存放搜索到的树节点到顶部节点的路径节点
 */
export function getPathByKey(curKey: string, data: any) {
  /** 存放搜索到的树节点到顶部节点的路径节点 */
  let result = []
  /**
   * 路径节点追踪
   * @param {*} curKey 树节点标识的值
   * @param {any} path 存放搜索到的树节点到顶部节点的路径节点
   * @param {*} data 树
   * @returns undefined
   */
  const traverse = (curKey: any, path: any, data: any) => {
    // 树为空时，不执行函数
    if (data.length === 0)
      return

    // 遍历存放树的数组
    for (const item of data) {
      // 遍历的数组元素存入path参数数组中
      path.push(item)
      // 如果目的节点的id值等于当前遍历元素的节点id值
      if (item.id === curKey) {
        // 把获取到的节点路径数组path赋值到result数组
        result = JSON.parse(JSON.stringify(path))
        return
      }

      // 当前元素的children是数组
      const children = Array.isArray(item.children) ? item.children : []
      // 递归遍历子数组内容
      traverse(curKey, path, children)
      // 利用回溯思想，当没有在当前叶树找到目的节点，依次删除存入到的path数组路径
      path.pop()
    }
  }
  traverse(curKey, [], data)
  // 返回找到的树节点路径
  return result as any[]
};

export function isEmpty(obj) {
  if (typeof obj === 'undefined' || obj === null || obj === '')
    return true
  else
    return false
}

/**
 * 替换第几个匹配项
 * @param str 需要被替换的文本
 * @param regex 正则表达式
 * @param replacement 替换成
 * @param n 替换第几个匹配项
 * @returns 替换后的文本
 */
export function replaceNth(str: string, regex: RegExp, replacement: string, n: number) {
  let count = 0
  return str.replace(regex, (match) => {
    count++
    if (count === n)
      return replacement

    return match
  })
}

export function fileSizeLabel(size?: number) {
  const fileSize = Number(size ?? 0)
  if (fileSize < 1048576)
    return `${(fileSize / 1024).toFixed(2)}kb`
  else if (fileSize < 1073741824)
    return `${(fileSize / 1048576).toFixed(2)}M`
  else
    return `${(fileSize / 1048576).toFixed(2)}G`
}

// 将 Base64 转换为 File 对象
export const base64ToFile = (base64: string, fileName: string) => {
  const arr = base64.split(',')
  const mime = arr[0].match(/:(.*?);/)![1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n--)
    u8arr[n] = bstr.charCodeAt(n)

  return new File([u8arr], fileName, { type: mime })
}

/**
 * 知识图谱数据格式转换
 * @param list
 * @returns
 */
export const iteratorMindData = (list: CourseKnowledgePointTreeVO[]) => {
  return list?.map((item) => {
    return {
      data: {
        _id: item.id,
        text: item.knowledgeName,
        expand: item.expanded,
      },
      children: iteratorMindData(item.children as CourseKnowledgePointTreeVO[]),
    }
  })
}
